@import "~react-image-gallery/styles/css/image-gallery.css";
.header-margin {
    margin-top: 3rem;
}

/** Font style */
.font-style {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 'Regular';
}

.font-style2 {
    font-family: 'Copperplate Gothic Light', sans-serif !important;

}

/* Font Sizes */

.xsmall-font-size {
    font-size: 10px;
}

.small-font-size {
    font-size: 12px;
}

.normal-font-size {
    font-size: 14px;
}

.medium-font-size {
    font-size: 16px;
}

.big-font-size {
    font-size: 18px;
}

.mega-font-size {
    font-size: 20px;
}

.giga-font-size {
    font-size: 22px;
}

/* Theme font color*/

.theme-font-color {
    color: #E40045 !important;
}

/* Theme bg color*/
.theme-bg-color {
    background-color: #E40045 !important;
}

/* Theme button color*/
.theme-button {
    border-width: 2px !important;
    border-color: #E40045 !important;
    color: #E40045 !important;
    border-radius: 10px;
}


.home-bg {
    /* width: 100%; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    background-image: url('../images/home/background.png');
    margin-top: 4rem;
    /* margin-top: 50px; */
}

.service-bg {
    /* width: 100%; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    background-image: url('../images/services/servicebg.jpg');
    /* margin-top: 50px; */
}

.services-bg-color {
    background-color: #F8D89C !important;
}

.blog-bg {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    background-image: url('../images/blog/bgblog.png');
    /* margin-top: 50px; */
}

.about-bg {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-image: url('../images/about/aboutbg.svg');
    /* margin-top: 50px; */
}

.page404-bg {
    width: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    background-image: url('../images/loading.svg');
    /* margin-top: 50px; */
}

.border-right-style {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.border-left-style {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.right-border-style {
    border-right: 5px solid #E40045;
}

.our-project-container {
    /* position: relative !important; */
    text-align: center !important;
}

.our-project-container:hover {
    opacity: 0.7 !important;
    color: #E40045 !important
}


/* .our-project-style { */
    /* position: absolute !important;
    top: 75% !important;
    left: 10% !important;
    color: #f1f1f1 !important */
        /* transform: translate(-30%, -30%) !important; */
/* } */

.text {
    background-color: #04AA6D;
    color: #E40045;
    font-size: 16px;
    padding: 16px 32px;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .home-bg {
        height: 100%;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .home-bg {
        height: 100%;
    }
}

.border-style {
    border-radius: 10px !important;
}

hr.footer-line {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgb(247, 243, 243) !important;

}

.watermark {
    position: relative;
    opacity: 0.15;
    z-index: -99;
}


.background {
    position: absolute;
    z-index: 0;
    display: block;
    min-height: 100%;
    min-width: 75%;
    opacity: 0.15;
}

.background-about {
    position: absolute;
    z-index: 0;
    display: block;
    min-height: 100%;
    min-width: 75%;
}

.bg-text-about {
    font-size: 400px;
    font-weight: bolder
}


.content {
    /* position:absolute; */
    z-index: 1;
}

.bg-text {
    color: #E40045;
    font-size: 180px;
    font-weight: bolder
}

.carousel-indicators li {
    height: 10px !important;
    width: 10px !important;
    border-radius: 100%;
}

.text-color-offers {
    color: #C49A6C;
}

.offer-button-container {
    left: 40px !important;
    top: 40px !important;
    z-index: 9 !important;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.offer-button {
    background-color: #fff;
    border-radius: 20px;
}

.offer-button:hover {
    background-color: #E40045;
    color: #fff;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-modal-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 10px;
    padding: 15px;
    margin-left: 5px;
    border-radius: 20px;
}

/* Links inside the dropdown */
.dropdown-modal-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: none;
}

/* Change color of dropdown links on hover */
.dropdown-modal-content a:hover {
    background-color: #fff;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-modal-content {
    display: block;
}



/* Calender style */
.MuiPickersToolbar-toolbar {
    background-color: #E40045 !important;
}

.MuiPickersDay-daySelected {
    background-color: #E40045 !important;
}

.MuiPickersStaticWrapper-staticWrapperRoot {
    background-color: #FFF5EB !important;
}

.MuiPickersCalendarHeader-iconButton {
    background-color: #000 !important;
}

.MuiSvgIcon-root {
    color: #fff !important;
}

.MuiIconButton-root {
    padding: 5px !important;
}

.calender-bg-color {
    background-color: #FFF5EB !important;
}

.modal-content {
    border-radius: 1.3rem !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #E40045 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #E40045 !important;
}

.MuiTypography-alignCenter {
    color: #E40045 !important;
}



.md-stepper-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;
}

.md-stepper-horizontal .md-step {
    display: table-cell;
    position: relative;
    padding: 30px;
}



.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}

.md-stepper-horizontal .md-step .md-step-circle {
    width: 85px;
    height: 85px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    padding: 5px;
    box-shadow: 1px 1px 1px 1px #dddd;

}


.md-stepper-horizontal .md-step.editable .md-step-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.md-stepper-horizontal .md-step.editable .md-step-circle:before {
    font-weight: 50;
    content: "\f040";
}

.md-stepper-horizontal .md-step .md-step-title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
}

.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
    text-align: center;
}


.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 75px;
    height: 1px;
    border-top: 1px dashed #000;
}

.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 50px;
}

.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 50px;
}


.our-team-container {
    position: relative;
    max-width: 400px;
    /* Maximum width */
    margin: 0 auto;
    /* Center it */
}

.our-team-container .our-team-content {
    position: absolute;
    /* Position the background text */
    bottom: 0;
    /* At the bottom. Use top:0 to append it to the top */
    background: rgb(0, 0, 0);
    /* Fallback color */
    background: rgba(0, 0, 0, 0.5);
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    /* Grey text */
    width: 100%;
    /* Full width */
    padding: 20px;
    /* Some padding */
}

.social-media-container {
    display: flex;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
}

.pointer {
    cursor: pointer !important;
}

.swiper-container {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide {
    background-color: transparent !important;
}

.nav-link:active {
    color: #E40045 !important
}

.testimonial-container {
    position: relative;
    width: 100%;

}

.testimonial-card {
    opacity: 1;
    display: block;
    width: 100%;
    height: 150px;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent !important;
}

.testimonial-container:hover .testimonial-card {
    opacity: 0.3;
}

.testimonial-container:hover .middle {
    opacity: 1;
}

.testimonial-container:hover .text {
    opacity: 0.3;
    width: 75px;
}


.testimonial-container:hover .testimonial {
    opacity: 0.3;
}

.scroll-view {
    overflow: scroll !important;
}

.header-font-color.a.active {
    color: #E40045 !important;
}

.container-ourproject {
    position: relative;
    width: 50%;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container:hover .image {
    opacity: 0.3;
}

.container:hover .middle {
    opacity: 1;
}

.toast-position {
    position: fixed
}

.custom-modal {
    width: 100% !important;
    height: 100% !important;
}

*/ .modal-dialog {
    position: fixed !important;
    margin-top: 70 !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
}

.modal-backdrop-loader {
    background-color: #fff !important;
    opacity: 1 !important;
}

.app-main {
    padding: 0px !important;
}

/* Social Icons */
#social_side_links {
    position: fixed;
    top: 320px;
    right: 30px;
    padding: 0;
    list-style: none;
    z-index: 99;

}

#social_side_links li a {
    display: block;
}

#social_side_links li a img {
    display: block;
    max-width: 100px;
    padding: 2px;
    border-radius: 3px;
    -webkit-transition: background .2s ease-in-out;
    -moz-transition: background .2s ease-in-out;
    -o-transition: background .2s ease-in-out;
    transition: background .2s ease-in-out;
    filter: grayscale(100%);


}


#social_side_links li a:hover img {
    filter: grayscale(0%);
}


.footer-icon:hover {
    background-image: linear-gradient(to right, #E40045, #fff);
}

.hiddenInput {
    width: 0;
    height: 0;
    border: 0
}

.hiddenInput:focus {
    background-color: none;
    opacity: 0;
}

.mobile_project {
    display: none;
}

@media (max-width: 768px) {
    .tab-btn {
        font-size: 9px !important;
    }
}

@media (max-width: 768px) {
    .dropdown-modal-content {
        display: none;
        position: absolute;
        background-color: #ffffff;
        min-width: 150px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        margin-top: 10px;
        padding: 15px;
        margin-left: 5px;
        border-radius: 20px;
    }
}

@media (max-width: 375px) {
    .view-gallery-style {
        display: none !important;
    }

    .mobile_project {
        display: block !important;
    }

    .dropdown-content {
        display: none !important;
    }
}

@media (max-width: 425px) {
    .view-gallery-style {
        display: none !important;
    }

    .mobile_project {
        display: block !important;
    }

    .dropdown-content {
        display: none !important;
    }
}


/* .view-gallery-style {
    display: none !important;
} */

@media (min-width: 1200px) {

    .h1,
    h1 {
        font-size: 2.1rem;
    }
}

.page-button.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.page404-container {
    position: relative;
    text-align: center;
  }

  /* Centered text */
.centered-404 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

@media screen and (min-width: 480px) {
    .load-img {
        width: auto;
    }
}


@media screen and (min-width: 992px) {
    .load-img {
        width: 40%;
    }
}

.custom-youtube-style {
    height: 300px;
}

@media screen and (min-width: 600px) {
    .custom-youtube-style {
        height: 75vh;
    }
}
