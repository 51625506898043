/*Data table styles */

.MuiDataGrid-root {
    border: 1px solid #E40045 !important

  }

  .MuiDataGrid-root .MuiDataGrid-columnsContainer {
    border-bottom: 1px solid #E40045 !important;
  }

.MuiSvgIcon-root {
    color:#E40045 !important
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: 1px solid #E40045 !important;
}

.table thead th {
  border-bottom: 1px solid #E40045 !important;
}