.founder-quoteimg {
    width: 80px;
    padding: 1em;
}

.founderdetails {
    border: 1px solid #000;
    padding: 1.5em;
    margin-bottom: 1em;
}

.founder-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.founder-message p {
    position: relative;
}



.founder-img {
    width: 90%;
    margin: auto;
}

/* @media (min-width: 600px) {
    
    .founder-content {
        position: relative;
    }
    .founderdetails {
        position: absolute;
        top: 40px;
        left: 38px;
        width: 60%;
        padding: 2em;
        padding-right: 4em;
    }
    .founder-img {
        position: absolute;
        top: -16px;
        left: auto;
        right: 10px;
        z-index: -1;
        width: 50%;
    }
    
    .founder-contact {
        flex-direction: row;
    }
} */

@media (min-width: 1000px) {

    .founder-content {
        position: relative;
        height: 70vh;
    }

    .founderdetails {
        position: absolute;
        top: 40px;
        left: 38px;
        width: 60%;
        padding: 2em;
        padding-right: 4em;
    }

    .founder-img {
        position: absolute;
        top: -14px;
        left: auto;
        right: 10px;
        /* z-index: -1; */
        width: 50%;
    }

    .founder-contact {
        flex-direction: column;
    }
}

@media (min-width: 1024px) {
    .founder-content {
        height: 88vh;
        width: 100%;
    }

    .founder-quoteimg {
        width: 160px;
    }

    .founderdetails {
        left: 72px;
        top: 75px;
        width: 85%;

    }

    .founder-img {
        width: 40%;
        top: -40px;
    }
}

@media (min-width: 1400px) {
    .founder-content {
        height: 70vh;
        width: 100%;
    }

    .founder-quoteimg {
        width: 160px;
    }

    .founderdetails {
        left: 72px;
        top: 75px;
        width: 85%;

    }

    .founder-img {
        width: 40%;
        top: -40px;
    }
}

@media (min-width: 375px) {
    .mobile_personal-font {
        font-size: 10px !important;
    }

}