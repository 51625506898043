

.rose-dot .slick-dots li.slick-active button:before{
	color: #E40045 !important;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}


.active.carousel-indicators-active {
	background-color:  #E40045;
}

.modal-content{
    border:0 !important;
}

.custom-slick-next-button .slick-next {
  margin-right: 0px !important;
  right: 0px !important;
  font-size: 0px !important;
}

.custom-slick-next-button .slick-prev {
    left: 3px !important;
    z-index: 600; 
}

.service-slick-next-button .slick-next {
    right: -35px !important;
    font-size: 0px !important;
}
  
.service-slick-next-button .slick-prev {
    left: -35px !important;
    z-index: 600; 
}


@media (max-width: 650px) {
    .service-slick-next-button .slick-prev{
        left: -18px !important;
        z-index: 600; 
    }

    .service-slick-next-button .slick-next {
        right: -18px !important;
        font-size: 0px !important;
    }
}