.form-loader-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999 !important;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(23, 20, 20, 0.1) !important;
}