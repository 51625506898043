/*Header menu border style*/
li {
    margin-bottom: 10px;
}

.header-font-color {
    display: inline-block;
    color: #000;
    text-decoration: none;
}

.header-font-color::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #E40045;
    transition: width .3s;
}

.header-font-color:hover::after {
    width: 50%;
    
}

.nav-link {
    display: inline-block !important;
    color: #000 !important;
    text-decoration: none !important;
    padding:10px 0px 0px 0px !important;
    margin:0px 10px 0px 10px !important
}

.nav-link::after {
    content: '' !important;
    display: block !important;
    width: 0 !important;
    height: 2px !important;
    background: #E40045 !important;
    transition: width .3s !important;
    color: black !important;
}

.nav-link:hover::after {
    width: 50% !important;
   
}
.nav-link.active {
    color:#E40045 !important;
    border-bottom:#E40045 2px solid !important;
    padding:10px 0px 0px 0px !important;
    margin:0px 10px 0px 10px !important;
}
.header-font-color.active::after {
    content: '' !important;
    display: block !important;
    width: 0 !important;
    height: 2px !important;
    background: #E40045 !important;
    transition: width .3s !important;
    color: black !important;
}
.header-font-color.active:hover::after {
    width:50% !important;
    color:black !important;
    border-bottom:none !important;
}


.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 14px 14px;
    /* padding: 8px 10px; */
    text-decoration: none;
    display: block;
    font-size:14px !important
  }

/* The container <div> - needed to position the dropdown content */
.service-dropdown {
    position: relative;
    display: inline-block;
    }

  /* Dropdown Content (Hidden by Default) */
  .service-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .service-dropdown-content a {
    color: black;
    /* padding: 14px 14px; */
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    font-size:14px !important
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }


  a.nav-link.active:hover {
    border-bottom: none !important;
    color: #000 !important;
}





  


