/* header */
header {
    background-color: #fff;
    box-shadow: 0px 5px 5px #00000020;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0.5em;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

/* brand logo */
.brand-container {
    width: 8em;
    padding: 0.15em;
}

a.logo img {
    vertical-align: middle;
}

/* icon size */
.icon-size {
    font-size: 1.75em;
}

/* two call to action button and link */
.consult-btn {
    border: 2px solid #e40045;
    padding: 0.5em;
    border-radius: 0.5em;
    color: #e40045;
    background-color: #fff;
    margin-right: 1em;
}

@media (max-width: 500px) {
    .consult-btn {
        padding: 0.3em;
    }
}

.cont-btn {
    color: #e40045;
    text-decoration: none;
}

/* small screen hide */
.hidesm {
    display: none;
}

/* nav container for all data */
.navdata-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;

}

/* nav */
nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: #ffeff4;
    width: 80%;
    visibility: hidden;
    transform: translateX(50%);
    opacity: 0;
    transition: all .3s;
}

/* menu icon */
.open {
    cursor: pointer;
}

/* close icon */
.exit {
    width: 2em;
    float: right;
    margin: .5em;
    cursor: pointer;
    color: #e40045;
}

/* navigation links */
ul {
    list-style-type: none;
    padding: 0;
    margin-top: 4em;
}

.nv-links {
    color: #0f0f0f;
    text-decoration: none;
    display: inline-block;

}

.active .nv-links {
    color: #e40045;
    font-weight: 600;
}

.nv-links::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #E40045;
    transition: width .3s;
    color: #000 !important;

}

.nv-links:hover::after {
    width: 50%;
    color: #000 !important
}



/* .nv-links:hover::after {
    color: #e40045;
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #E40045;
    transition: width .3s;
} */


/* li {
    margin-bottom: 10px;
} */

.nav-link {
    display: inline-block !important;
    color: #000 !important;
    text-decoration: none !important;
    padding: 10px 0px 0px 0px !important;
    margin: 0px 10px 0px 10px !important;
    font-size: 14px !important;
}

.nav-link::after {
    content: '' !important;
    display: block !important;
    width: 0 !important;
    height: 2px !important;
    background: #E40045 !important;
    transition: width .3s !important;
    color: black !important;
}

.nav-link:hover::after {
    width: 50% !important;

}

.nav-link.active {
    color: #E40045 !important;
    border-bottom: #E40045 2px solid !important;
    padding: 10px 0px 0px 0px !important;
    margin: 0px 10px 0px 10px !important;
}

/* extra class added thorugh js */
.open-nav {
    visibility: unset;
    opacity: 1;
    transform: translateX(0);
}


/* sub navigation menu */
.submenu {
    padding: 0 2em;
}

.submenu ul {
    margin: 0 0 1em;
}

.submenu a {
    font-size: 0.85em;
    font-weight: 400;
    color: #353535;
    text-decoration: none;
    margin-bottom: 0.5em;
}





@media (min-width: 500px) {
    .navdata-container {
        width: 30%;
    }

    .follow-us {
        font-size: '30px' !important
    }
}

@media (min-width: 1024px) {

    /* logo */
    .brand-container {
        width: 14em;
    }

    /* header */
    header {
        overflow: visible;
        padding: 1em 2em;
    }

    /* hide menu and close icon */
    .open,
    .exit {
        display: none;
    }

    /* nav for bigger display */
    nav {
        visibility: unset;
        background: none;
        position: unset;
        height: auto;
        opacity: 1;
        transform: translateX(0);
    }

    .ulnav {
        display: flex;
        margin-top: auto;
        width: auto;
        float: right;
        margin: 0;
    }

    .nv-links {
        display: block;
        padding: 1em;
        font-size: 0.85em;
    }

    /* sub navigation */
    .sub {
        position: relative;
    }

    .submenu {
        display: none;
    }

    .sub:hover .submenu {
        min-width: 200px;
        display: flex;
        position: absolute;
        top: 3.3em;
        right: 0;
        background: white;
        padding: 1em 2em;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.07);
    }

    .submenu ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 1.5em 0 0;
        width: 100%;
    }

    .submenu li a {
        padding: 0 0 .5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;
    }

    /* nav container reverse row*/
    .navdata-container {
        flex-direction: row-reverse;
        width: 80%;
    }

    .follow-us {
        font-size: '50px'
    }
}

@media (min-width: 1200px) {
    .hidesm {
        display: flex;
    }

    .ctalink-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
    }

    .cont-btn,
    .consult-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.85em;
    }

    .icon-size {
        display: none;
    }

    nav {
        display: flex;
        justify-content: center;
    }


    .collapsed .sidebar-collaps nav {
        display: block;
        justify-content: center;
    }

    .follow-us {
        font-size: '50px' !important
    }
}

@media (min-width: 1600px) {

    .cont-btn,
    .consult-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1em;
    }

    .nv-links {
        font-size: 1em;
    }

    .ctalink-container {
        width: 30%;
    }

    .follow-us {
        font-size: '50px' !important
    }
}

/* .sidebar-collaps nav {
    position: fixed;

    height: 100vh;
    background-color: #ffeff4;
    width: 80%;
    visibility: unset;

    opacity: 1;
    transition: all .3s;
}

.toggled .sidebar-collaps nav {
    position: fixed;
    background-color: #ffeff4;
    width: auto;
    visibility: unset;
    opacity: 1;
    transition: all .3s;
} */

.toggled .sidebar-collaps nav {
    position: inherit;
    top: 0;
    right: 0;
    height: auto;
    background-color: #ffeff4;
    width: 100%;
    visibility: unset;
    transform: unset;
    opacity: 5;
    transition: all .3s;
}

.description-content ul {
    list-style-type: disc;
    padding-left: 30px;
    margin-top: 0em;
}

.description-content ul li::marker {
    font-size: 20px;
}